import React, { useState } from 'react';
import { API_URL, TOKEN_NAME } from 'common/config';
import fetch from 'unfetch';

export const FormContext = React.createContext({});

export const FormProvider = props => {
	const [values, setValues] = useState({ date: new Date() });
	const [questions, setQuestions] = useState(null);
	const [titles, setTitles] = useState(null);

	const getQuestions = () => {
		const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

		fetch(`${API_URL}/Report/metadata`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				setQuestions(response);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const getTitles = () => {
		const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

		fetch(`${API_URL}/Title`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				setTitles(response.items);
			})
			.catch(error => {
				console.log(error);
			});
	};

	return (
		<FormContext.Provider
			value={{
				setValues,
				values,
				questions,
				titles,
				getQuestions,
				getTitles,
			}}
		>
			{props.children}
		</FormContext.Provider>
	);
};

export const FormConsumer = FormContext.Consumer;
